import React from 'react';

import './Pages.scss';

import Tile from '../common/Tile';

import languageData from '../lang/langContact.json';

class Contacts extends React.Component {

    // constructor(props) {
    //     super(props)
    // }

    componentDidMount() {
        this.props.updateSvgWidth()
    }

    render() {
        var language = languageData[this.props.language]

        return (
            <React.Fragment>
                <div className="row contact">
                    <Tile title={language["tile.contacs.title"]} tileId="svg-tile">
                        {language["contacts.text"]}
                        {
                            language["contacts.main"].map(entry => (
                                <div key={entry["name"]} className="contact-entry">
                                    <p className="bold">{entry["name"]}</p>
                                    <span className="material-icons md-18 md-light">email</span>
                                    <a href={"mailto:" + entry["mail"]} className="web-link">{entry["mail"]}</a>
                                </div>
                            ))
                        }
                    </Tile>
                    <Tile title={language["title.groups.title"]}>
                        {
                            language["groups"].map(entry => (
                                <div key={entry["name"]}>
                                    <p className="bold">{entry["name"]}</p>
                                    {entry["description"] ? <p>{entry["description"]}</p> : null}
                                    {entry["link"] ? (
                                        <React.Fragment>
                                            <span className="material-icons md-18 md-light">open_in_new</span>
                                            <a href={entry["link"]} className="web-link" target="_blank" rel="noopener noreferrer">{language["link.name"]}</a>
                                        </React.Fragment>

                                    ) : (null)}
                                </div>
                            ))
                        }
                    </Tile>
                </div>
            </React.Fragment>
        )
    }
}

export default Contacts;
