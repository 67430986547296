import React from 'react';
import * as d3 from "d3";

import './Models.scss';

import { getFeatureName } from '../common/helpers';


class DecisionTree extends React.Component {

    constructor(props) {
        super(props)

        this.svg_name = "#dtSVG"
        this.colors = ["#FFC278", "#009474", "#C71429", "#64C9FA", "#FFD3C4"]

        this.state = {
            drawn: false,
        }
        this.nodeSize = 30
    }

    treeIsNull() {
        return (this.props.nodes == null || this.props.links == null || this.props.treeSizes == null)
    }


    componentDidMount() {
        if (!this.treeIsNull()) {
            this.drawTree()
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!this.treeIsNull()) && (!this.state.drawn)) {
            this.drawTree()
        }
        else if ((this.props.currentHighlight !== prevProps.currentHighlight) && (!this.treeIsNull())) {
            var currentHighlight
            if (this.props.currentHighlight != null)
                currentHighlight = this.props.currentHighlight
            else
                currentHighlight = []

            d3.select(this.svg_name).selectAll(".treenode")
                .attr("class", d => (currentHighlight.includes(d.node_id)) ? "highlight node treenode fill-" + d.decision_raw : "node treenode fill-" + d.decision_raw)

            d3.select(this.svg_name).selectAll(".split-val")
                .attr('class', d => (currentHighlight.includes(d.node_id)) ? "label-tr split-val highlight" : "label-tr split-val")
        }
    }

    drawTree() {
        this.setState({
            drawn: true,
        })
        const svg = d3.select(this.svg_name)
        var viewBox = "0 0 " + this.props.width.toString() + " " + this.props.height.toString()

        svg.append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", viewBox)
            .classed("svg-content", true)

        var nodes = this.props.nodes
        var treesize = this.props.treeSizes

        // calc distances between nodes
        var largestLayerSize = Math.max.apply(null, Object.keys(treesize).map(i => treesize[i]))

        // create node locations (horizontal)
        var xdist = this.props.width / largestLayerSize
        var ydist = this.props.height / Object.keys(treesize).length
        nodes.map(function (d) {
            d["x"] = (((d.node_nth_from_left - 0.5) + ((largestLayerSize - treesize[d.layer]) / 2)) * xdist)
            d["y"] = (d.layer + 0.5) * ydist
            return null
        })


        // draw links
        var verticalLink = d3.linkVertical()
            .x(d => d.x)
            .y(d => d.y)

        var update = svg.append("g")
        update.selectAll(".link")
            .data(this.props.links)
            .enter().append("path")
            .attr("class", "link")
            .attr("id", d => "treelink-" + d.source.toString() + "-" + d.target.toString())
            .attr('d', function (d) {
                var node_to = nodes[d.target]
                var node_from = nodes[d.source]
                let datum = {
                    source: {
                        x: node_from.x,
                        y: node_from.y
                    },
                    target: {
                        x: node_to.x,
                        y: node_to.y
                    }
                };
                return verticalLink(datum, 0)
            })
            .attr("fill", "none")
            .style("stroke-width", d => Math.sqrt(d.value))

        // draw nodes
        var node = update.selectAll(".node")
            .data(this.props.nodes)
            .enter()
            .append("g")
            .attr("transform", d => "translate(" + d.x + "," + d.y + ")")
            .attr("class", "treeg")


        var currentHighlight
        if (this.props.currentHighlight != null)
            currentHighlight = this.props.currentHighlight
        else
            currentHighlight = []

        // node.append("circle")
        //     .attr("class", "node")
        //     .attr("class", "treenode")
        //     .attr("r", this.nodeSize)
        //     .style("fill", d => (currentHighlight.includes(d.node_id)) ? "rgb(108, 181, 217)" : this.colors[d.decision_raw])
        //     .on('click', d => (this.props.handleClick ? this.props.handleClick(d) : null))

        node.append("rect")
            .attr("class", d => (currentHighlight.includes(d.node_id)) ? "highlight node treenode fill-" + d.decision_raw : "node treenode fill-" + d.decision_raw)
            .attr("width", this.nodeSize * 2)
            .attr("height", this.nodeSize)
            .attr("rx", "10")
            .attr("ry", "10")
            .attr("x", -this.nodeSize)
            .attr("y", -this.nodeSize / 2)
            .on('click', d => (this.props.handleClick ? this.props.handleClick(d) : null))

        node.append("text")
            .attr('class', 'label-tr')
            .attr("y", "-1.9em")
            .text(d => d.is_leave ? getFeatureName(this.props.language, this.props.classNamesMap, d.decision)
                : getFeatureName(this.props.language, this.props.featureNamesMap, d.split_feature))

        node.append("text")
            .attr('class', d => (currentHighlight.includes(d.node_id)) ? "label-tr split-val highlight" : "label-tr split-val")
            .attr("y", "0.25em")
            .text(function (d) {
                if (!d.is_leave) {
                    var split_value = d.split_value >= 100 ? String(Math.round(d.split_value)) : String(d.split_value.toFixed(1))
                    return "≤ " + split_value
                }

            })
            .on('click', d => (this.props.handleClick ? this.props.handleClick(d) : null))

        // Remove old D3 elements
        update.exit()
            .remove();
    }

    render() {
        return (
            <React.Fragment>
                <svg id="dtSVG" className={this.props.className} width={this.props.width} height={this.props.height}></svg>
            </React.Fragment>
        )
    }
}

export default DecisionTree;