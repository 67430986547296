import React from 'react';

import './UiExplanation.scss';

class UiExplanation extends React.Component {

    constructor(props) {
        super(props)

        var currentChildren = this.props.currentChildren
        if (!((currentChildren >= 0) && (currentChildren < this.props.children.length)))
            currentChildren = 0

        this.state = {
            currentChildren: currentChildren,
            visible: true,
        }


    }

    getCurrentChildren() {
        if (this.props.children != null) {
            return this.props.children[this.state.currentChildren]
        }
    }

    prevClicked() {
        var currentChildren = this.state.currentChildren
        if (currentChildren > 0) {
            this.setState({
                currentChildren: currentChildren - 1
            })
        }
    }

    nextClicked() {
        var currentChildren = this.state.currentChildren

        if (currentChildren < this.props.children.length - 1) {
            this.setState({
                currentChildren: currentChildren + 1
            })
        }
    }

    dotClicked(index) {
        if (this.state.currentChildren !== index) {
            this.setState({
                currentChildren: index
            })
        }

    }

    getDotNav() {
        return (
            this.props.children.map((item, index) =>
                <li key={index} className={this.state.currentChildren === index ? "highlight" : ""} onClick={() => this.dotClicked(index)}></li>
            )
        )
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentChildren !== this.props.currentChildren) {
            this.setState({
                currentChildren: this.props.currentChildren,
            })
        }
    }

    toggleVisibleClicked() {
        this.setState({
            visible: !this.state.visible,
        })
    }

    render() {
        return (
            <div className="row guide">
                {
                    this.props.guideModeOn
                    &&
                    <div className="container c-1-1 dark-background" >
                        {
                            !this.state.visible &&
                            <button className="icon bulb">
                                <i className={`material-icons-outlined md-36 md-light`}>emoji_objects</i>
                            </button>
                        }
                        {
                            this.state.visible
                            &&
                            <React.Fragment>
                                <div className="row text">
                                    {this.getCurrentChildren()}
                                </div>
                                <div>
                                    {
                                        (this.props.children.length > 1)
                                        &&
                                        <React.Fragment>
                                            <div className="row">
                                                <button className="icon" onClick={() => this.prevClicked()}>
                                                    <i className={`material-icons-outlined md-24 md-light`}>chevron_left</i>
                                                </button>
                                                <ul className="dotNav">
                                                    {this.getDotNav()}
                                                </ul>
                                                <button className="icon" onClick={() => this.nextClicked()}>
                                                    <i className={`material-icons-outlined md-24 md-light`}>chevron_right</i>
                                                </button>
                                            </div>

                                        </React.Fragment>
                                    }
                                </div>
                            </React.Fragment>
                        }
                        <button className="icon expand" onClick={() => this.toggleVisibleClicked()}>
                            <i className={`material-icons-outlined md-24 md-light`}>{this.state.visible ? "indeterminate_check_box" : "add_box"}</i>
                        </button>

                    </div>
                }
            </div>
        )
    }
}

export default UiExplanation;
