import React from 'react';

import './Pages.scss';

import Tile from '../common/Tile';
import NeuralNetwork from '../models/NeuralNet';
import Table from '../common/Table';
import UiExplanation from '../common/UiExplanation';
import Loading from '../common/Loading';

import { buildQuery, getFeatureName } from '../common/helpers';

import languageData from '../lang/langNNInspect.json';


class InspectionNN extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            currentHighlight: 1,
            fetchedWeights: false,
            fetchedInspection: false,
            currentRows: this.getRows(1, []),
            mlpLinks: null,
            highlightSingleTableRow: null,
        }
    }

    prevClicked() {
        var currentHighlight = this.state.currentHighlight

        if (currentHighlight > 1) {
            currentHighlight = currentHighlight - 1

            var currentRows = this.getRows(currentHighlight, this.state["x0_step-" + (currentHighlight - 1)])
            this.setState({ currentHighlight, currentRows })
        }
    }

    nextClicked() {
        var currentHighlight = this.state.currentHighlight

        if (currentHighlight < this.props.structureNN.length) {
            currentHighlight = currentHighlight + 1

            var currentRows = this.getRows(currentHighlight, this.state["x0_step-" + (currentHighlight - 1)])
            this.setState({ currentHighlight, currentRows })
        }
    }

    getRows(currentHighlight, values) {
        var language = languageData[this.props.language]

        if (values == null || values.length === 0) {
            return []
        }
        else {
            var currentRows = []
            for (var i = 0; i < this.props.structureNN[currentHighlight - 1]; ++i) {
                var variableName
                if (currentHighlight === 1) {
                    variableName = this.props.featureNames[i]
                }
                else if (currentHighlight === this.props.structureNN.length) {
                    variableName = language["text.prediction"]
                }
                else {
                    variableName = "Neuron " + String(i)
                }
                var value = values[i] //this.state["x0_step-" + (currentHighlight - 1)][i]
                value = value.toFixed(2)
                currentRows.push([variableName, value])

            }
            return currentRows
        }
    }

    getVariableNames(currentHighlight) {
        var variableNames
        if (currentHighlight === 1) {
            variableNames = this.props.featureNames
        }
        else if (currentHighlight === this.props.structureNN.length) {
            variableNames = ["Prediction"]
        }
        else {
            variableNames = []
            for (var i = 0; i < this.props.structureNN[currentHighlight - 1]; ++i) {
                variableNames.push("Neuron " + (i + 1))
            }
        }
        return variableNames
    }

    loadData() {
        var date = new Date()
        this.startTime = date.getTime()
        var query = buildQuery(20, this.props.l1Reg, this.props.orthReg, this.props.randomIndex, this.props.dataset)
        fetch(this.props.baseUrl + "l1oreg/model/training/weights" + query)
            .then(res => res.json())
            .then(res => this.insertWeights(res))
            .catch((error) => {
                console.error('Error:', error);
            })
        fetch(this.props.baseUrl + "l1oreg/model/introspection" + query)
            .then(res => res.json())
            .then(res => this.insertInspection(res))
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    insertWeights(res) {
        this.setState({
            mlpLinks: res.mlp_links,
            fetchedWeights: true,
        })
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching NN epoch 20 took", endTime - this.startTime, "ms")
    }

    insertInspection(res) {
        var steps = {}
        for (var key of Object.keys(res)) {
            steps[key] = res[key]
        }
        this.setState(Object.assign(steps, {
            correctResultRaw: res.correct_result_raw,
            correctResult: res.correct_result,
            fetchedInspection: true,
            currentRows: this.getRows(this.state.currentHighlight, steps["x0_step-" + (this.state.currentHighlight - 1)])
        }))
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching Inspection data took", endTime - this.startTime, "ms")
    }

    componentDidMount() {
        this.props.updateSvgWidth();
        this.loadData()
    }

    onHover(id) {
        var variableName = null
        if (id != null) {
            variableName = this.state.currentRows[id][0]

        }
        this.setState({
            highlightSingleTableRow: variableName
        })
    }

    render() {
        var language = languageData[this.props.language]

        return (
            <React.Fragment>
                <UiExplanation guideModeOn={this.props.guideModeOn} currentChildren={this.state.currentHighlight === this.props.structureNN.length ? 1 : 0}>
                    {language["guideMode.explanations"].map(text => <p key={text}>{text}</p>)}
                </UiExplanation>
                <div className="row">
                    <Tile title={language["tile.insp.title"]} tileId="svg-tile">
                        <div className="actionBar">
                            <div className="layerNavigation">
                                <button className="icon" disabled={!this.state.fetchedWeights || this.state.currentHighlight === 1} onClick={() => this.prevClicked()} title={language["tile.insp.prev"]}>
                                    <i className={`material-icons-outlined md-36 md-light ${this.state.currentHighlight === 1 ? "md-inactive" : ""}`}>navigate_before</i>
                                </button>
                                <span className="bold">{language["tile.pred.layer"] + " " + this.state.currentHighlight}</span>
                                <button className="icon" disabled={!this.state.fetchedWeights || this.state.currentHighlight === this.props.structureNN.length} onClick={() => this.nextClicked()} title={language["tile.insp.next"]}>
                                    <i className={`material-icons-outlined md-36 md-light ${this.state.currentHighlight === this.props.structureNN.length ? "md-inactive" : ""}`}>navigate_next</i>
                                </button>
                            </div>
                        </div>
                        <Loading loading={!this.state.fetchedWeights} />
                        <NeuralNetwork
                            structure={this.props.structureNN}
                            width={this.props.widthSvg}
                            height={this.props.heightSvg}
                            currentHighlight={this.state.currentHighlight}
                            epoch={20}
                            mlpLinks={this.state.mlpLinks}
                            featureNames={this.props.featureNames}
                            outputNames={this.props.outputNames}
                            onHover={id => this.onHover(id)} />

                        <div className="parameterInfo">
                            <span className="infoIcon">
                                <i className="material-icons-outlined md-18 md-light">info</i>
                            </span>
                            <p>{language["l1Text"] + " " + this.props.l1Reg}, {language["orText"] + " " + this.props.orthReg}</p>
                        </div>
                    </Tile>
                    <Tile title={language["tile.pred.title"]}>
                        <Table
                            currentHeader={language["tile.pred.table"]}
                            currentRows={this.state.currentRows}
                            hightlightFeatureRow={this.state.highlightSingleTableRow}>
                            <h2>{language["tile.pred.layer"] + " " + this.state.currentHighlight}</h2>
                            <p>{language["tile.pred.class"] + " " + getFeatureName(this.props.language, this.props.classNamesMap, this.state.correctResult)
                                + " (" + this.state.correctResultRaw + ")."}</p>
                        </Table>
                    </Tile>
                </div>
            </React.Fragment>
        )
    }
}

export default InspectionNN;
