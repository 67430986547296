import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.scss';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div>
                    <ul>
                        <li>
                            <Link className='text-link web-link' to="/contacts">
                                <span>Kontakt</span>
                            </Link>
                        </li>
                        <li>
                            <a className='web-link' href="https://www.ipa.fraunhofer.de/de/impressum.html" target="_blank" rel="noopener noreferrer">Impressum</a>
                        </li>
                    </ul>
                </div>
            </footer>
        )
    }
}

export default Footer;
