import React from 'react';

class Loading extends React.Component{

    render(){
        return(
            <React.Fragment>
                {
                    this.props.loading && 
                    <div>
                        <h2>Loading</h2>
                    </div>
                }
            </React.Fragment>
        )
    }
}

export default Loading;