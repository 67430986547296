import React from 'react';
import './Tile.scss';

class Tile extends React.Component {
    render() {
        return (
            <div className="container c-1-2 dark-background">
                <div className="tileContainer">
                    <p className='section-head'>{this.props.title}</p>
                    <div className={this.props.class} id={this.props.tileId}>{this.props.children}</div>
                </div>
            </div >
        )
    }
}

export default Tile;
