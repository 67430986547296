import React from 'react';

import './Table.scss'

class Table extends React.Component {

    getRows() {
        return (
            this.props.currentRows.map((row, index) =>
                <tr
                    key={index + row[0]}
                    className={(row[0] === this.props.hightlightFeatureRow) ? "hightlightRow" : null}>
                    {row.map(value => <td key={value}>{value}</td>)}
                </tr>
            )
        )
    }

    render() {
        return (
            <div className={this.props.className}>
                {this.props.children}
                <table>
                    <tbody>
                        <tr>
                            {this.props.currentHeader.map(value => <th key={value}>{value}</th>)}
                        </tr>
                        {this.getRows()}
                    </tbody>
                </table>
            </div>

        )
    }
}

export default Table;