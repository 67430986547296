export function buildQuery(epoch, l1, orth, indx, dataset="steel_faults_large", language="de"){
    return "?epoch=" + epoch + "&l1=" + l1 + "&orth=" + orth + "&indx=" + indx + "&dataset=" + dataset + "&lan=" + language
}

export function getFeatureName(language, featureNamesMap, name){
    if (language != null){
        return language === "de" ? featureNamesMap[name] : name
    }
    return name
}

export function getClassNames(language, classNameMap){
    var keys = Object.keys(classNameMap)
    if (language === "en"){
        return keys
    } 
    else {
        var names = []
        for (var key of keys){
            names.push(classNameMap[key])
        }
        return names
    }

}