import React from 'react';
import './Graphs.scss';

import { Line } from 'react-chartjs-2';


class LineGraph extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();

        var [data, options] = this.getData()
        this.state = {
            data: data,
            options: options,
        }

    }

    getData() {
        var labels = []
        for (var i = 0; i < this.props.data.length; ++i) {
            labels.push(i + 1)
        }
        var data = {
            labels: labels,
            datasets: [
                {
                    label: this.props.labelHover,
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.props.data,
                }
            ],
        };
        data.datasets[0].backgroundColor = this.props.color;
        data.datasets[0].borderColor = this.props.color;
        data.datasets[0].pointBorderColor = this.props.color;
        data.datasets[0].pointHoverBackgroundColor = this.props.color;
        data.datasets[0].pointHoverBorderColor = this.props.color;
        var options = {
            title: {
                display: true,
                text: this.props.title,
                fontColor: '#EFEEEE',
            },
            legend: {
                display: false,
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: "#EFEEEE",
                    },
                    scaleLabel: {
                        display: false,
                        labelString: "Y text",
                        fontColor: '#EFEEEE'
                      }
                }],
                xAxes: [{
                    ticks: {
                        fontColor: "#EFEEEE",
                    },
                    scaleLabel: {
                        display: true,
                        labelString: this.props.xLabel,
                        fontColor: '#EFEEEE'
                      }
                }]
            },
        }
        
        return [data, options]
    }

    componentDidMount() {
        // console.log(this.chartRef)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            var [data, options] = this.getData()
            this.setState({
                data: data,
                options: options,
            })
        }
    }

    render() {
        // data has to be part of state for reset to work correctly
        return (
            <div className="line-chart">
                <Line ref={this.chartRef} data={this.state["data"]} options={this.state["options"]} />
            </div>
        )
    }
}

export default LineGraph;