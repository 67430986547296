import React from 'react';

import Navigation from './Navigation';

import languageData from '../lang/langNav.json';


function WithNav(WrappedComponent) {

    return class extends React.Component {

        render() {
            var language = languageData[this.props.language]

            return (
                <React.Fragment>
                    <Navigation lang={language} currentLocation={this.props.currentLocation} previousLocation={this.props.previousLocation} nextLocation={this.props.nextLocation} isMobile={this.props.isMobile}></Navigation>
                    <WrappedComponent {...this.props} />
                </React.Fragment >
            )
        }
    };
}

export default WithNav;