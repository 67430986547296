import React from 'react';

import './Accordion.scss'

class Card extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            "visible": false,
        }
    }

    toggleVisible() {
        this.setState({
            "visible": !this.state.visible,
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="input-group">
                    <input onClick={() => this.toggleVisible()} type="checkbox" id={this.props.id_key} />
                    <label htmlFor={this.props.id_key}>{this.props.label}</label>
                </div>
                <div className={"input-wrapper " + (this.state.visible ? "visible" : "")}>
                    {this.props.children}
                </div>
            </React.Fragment>
        )
    }
}

export default Card;