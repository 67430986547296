import React from 'react';
import './Navigation.scss';

import { Link } from "react-router-dom";


function ListItem(props) {
    return (
        <li className={props.active}>
            <Link className='text-link' to={props.target}>
                {props.value}
                {
                    props.dot &&
                    <span className="dot"></span>
                }
            </Link>
        </li>
    );
}

function CurrentNavItem(props) {
    // for (var i = 0; i < props.arr.length; i++) {
    //     if (props.arr[i][1] === props.currentLoc) {
    //         return (
    //             <ListItem key={props.arr[i]} value={props.arr[i][0]} target={props.arr[i][1]} active={"active"} dot={false} />
    //         );
    //     }
    // }
    return (
        <ListItem key={props.arr} value={props.arr[0]} target={props.arr[1]} active={"active"} dot={false} />
    )
}

class Navigation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            navOpen: false,
            subpageVisible: false,
            currentNavIndex: -1
        }

    }

    componentDidMount() {
        for (var i = 0; i < this.props.lang["navItems"].length; i++) {
            if (this.props.lang["navItems"][i][1] === this.props.currentLocation) {
                this.setState({ "currentNavIndex": i })
            }
        }
    }

    toggleOpen() {
        this.setState({
            "navOpen": !this.state.navOpen,
        })
    }

    LinkClicked(e) {
        if (this.props.nextLocation === "") {
            e.preventDefault();
        }
    }

    render() {
        return (
            <div className="row">
                {!this.props.isMobile ?
                    (<div className="navigation">
                        <Link className='arrow-link' to={this.props.previousLocation}>
                            <button className="icon" title={this.props.lang['prev']} >
                                <i className={`material-icons-outlined md-36 md-light`}>arrow_back_ios</i>
                            </button>
                        </Link>
                        <ul>
                            {this.props.lang["navItems"].map((item) =>
                                <ListItem key={item} value={item[0]} target={item[1]} active={this.props.currentLocation === item[1] ? "active" : null} dot={true} />
                            )}
                        </ul>
                        <Link className='arrow-link' to={this.props.nextLocation} onClick={(event) => this.LinkClicked(event)}>
                            <button className="icon" title={this.props.lang['next']}>
                                <i className={`material-icons-outlined md-36 md-light ${this.props.nextLocation === "" ? "md-inactive" : ""}`}>arrow_forward_ios</i>
                            </button>
                        </Link>
                    </div>) : (
                        <React.Fragment>
                            <div className="navigation mobile">
                                {this.state.currentNavIndex !== -1
                                    &&
                                    <React.Fragment>
                                        <Link className='arrow-link' to={this.props.previousLocation}>
                                            <button className="icon" title={this.props.lang['prev']} >
                                                <i className={`material-icons-outlined md-24 md-light`}>arrow_back_ios</i>
                                            </button>
                                        </Link>
                                        {/* <CurrentNavItem arr={this.props.lang["navItems"]} currentLoc={this.props.currentLocation}></CurrentNavItem> */}
                                        <CurrentNavItem arr={this.props.lang["navItems"][this.state.currentNavIndex]}></CurrentNavItem>
                                        <Link className='arrow-link' to={this.props.nextLocation} onClick={(event) => this.LinkClicked(event)}>
                                            <button className="icon" title={this.props.lang['next']}>
                                                <i className={`material-icons-outlined md-24 md-light ${this.props.nextLocation === "" ? "md-inactive" : ""}`}>arrow_forward_ios</i>
                                            </button>
                                        </Link>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="nav-expand">
                                <div>
                                    <button
                                        onClick={() => this.toggleOpen()}
                                        className="icon" title={this.props.lang['menu']}>
                                        {!this.state.navOpen ? (
                                            <i className="material-icons-outlined md-24 down">keyboard_arrow_down</i>
                                        ) : (
                                                <i className="material-icons-outlined md-24 up">close</i>
                                            )}
                                    </button>
                                </div>
                            </div>
                            {
                                this.state.navOpen
                                &&
                                <div className="nav-list">
                                    <ul>
                                        {this.props.lang["navItems"].map((item) =>
                                            <ListItem key={item} value={item[0]} target={item[1]} active={this.props.currentLocation === item[1] ? "active" : null} dot={false} />
                                        )}
                                    </ul>
                                </div>
                            }

                        </React.Fragment>

                    )
                }

            </div>
        )
    }
}

export default Navigation;