import React from 'react';

import Tile from '../common/Tile';
import Accordion from '../common/Accordion';
import Card from '../common/Card';
import DecisionTree from '../models/DecisionTree';
import Table from '../common/Table';
import BarGraph from '../charts/BarGraph';
import DoughnutGraph from '../charts/DoughnutGraph';
import UiExplanation from '../common/UiExplanation';
import Loading from '../common/Loading';

import { buildQuery, getFeatureName, getClassNames } from '../common/helpers';

import languageData from '../lang/langExplanations.json';

class Explanation extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            fetched: false,
            clickedOn: null,
            dataDoughnutClass: null,
            dataDoughnutCount: null,
            percentageCount: null,
            loading: true,
            explanationPage: 0,
        }

        this.handleNodeClick = this.handleNodeClick.bind(this)
        this.openGlobalExp = this.openGlobalExp.bind(this)
        this.openLocalExp = this.openLocalExp.bind(this)
    }

    openGlobalExp(value) {
        if (value === false) {
            this.setState({explanationPage: 1})
        }
    }

    openLocalExp(value) {
        if (value === false) {
            this.setState({explanationPage: 2})
        }
    }

    getDecisionRuleRows(fetched) {
        if (fetched) {
            var rows = []
            var total_samples = 1
            if (this.state.nodes != null) {
                total_samples = this.state.nodes[0].n_samples
            }
            for (var i = 0; i < this.state.nRules; ++i) {
                var rule = this.state.rules[this.props.language][i]["rule"]
                var n_samples = this.state.rules[this.props.language][i]["n_samples"] / total_samples
                n_samples = (n_samples * 100)
                n_samples = n_samples.toFixed(1) + "%"
                var decision = this.state.rules[this.props.language][i]["decision"]
                rows.push([rule, n_samples, decision])
            }
            return rows
        }
        return []
    }

    getImportances(fetched) {
        if (fetched) {
            var topFiveData = []
            var topFiveLabels = []
            for (var i = 0; i < this.state.importances.length; ++i) {
                var currentImportance = this.state.importances[i]

                if (currentImportance[1] > 0) {
                    topFiveLabels.push(getFeatureName(this.props.language, this.props.featureNamesMap, currentImportance[0]))
                    topFiveData.push(currentImportance[1].toFixed(2))
                }
                if (i >= 5) {
                    break
                }
            }
            return [topFiveLabels, topFiveData]
        }
        return [[], []]
    }

    handleNodeClick(node) {
        var total_samples = this.state.nodes[0].n_samples
        var node_samples = this.state.nodes[node.node_id].n_samples
        this.setState({
            clickedOn: node.node_id,
            dataDoughnutClass: this.state.nodes[node.node_id].class_distribution,
            dataDoughnutCount: [node_samples, total_samples - node_samples],
            percentageCount: (100 * node_samples / total_samples).toFixed(0)
        })
    }

    getPercentage(numbers) {
        return numbers ? (100 * numbers[0] / (numbers[0] + numbers[1])).toFixed(0) : "-"
    }

    componentDidMount() {
        this.props.updateSvgWidth();
        var date = new Date()
        this.startTime = date.getTime()
        var query = buildQuery(20, this.props.l1Reg, this.props.orthReg, this.props.randomIndex, this.props.dataset)
        fetch(this.props.baseUrl + "l1oreg/explanation/global" + query)
            .then(res => res.json())
            .then(res => this.insertData(res))
            .catch((error) => {
                console.error('Error:', error);
            })
        fetch(this.props.baseUrl + "l1oreg/explanation" + query)
            .then(res => res.json())
            .then(res => this.insertTreeData(res))
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    insertData(res) {
        this.setState({
            nRules: res.n_rules,
            rules: res.rules,
            importances: res.feature_importances.importances,
            fetched: true,
        })
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching globalData took", endTime - this.startTime, "ms")
    }

    insertTreeData(res) {
        this.setState({
            nodes: res.nodes,
            links: res.links,
            treeSizes: res.tree_sizes,
            scores: res.scores,
            loading: false,
        })
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching treeStructure took", endTime - this.startTime, "ms")
    }

    render() {
        var [topLabels, topData] = this.getImportances(this.state.fetched)
        var query = buildQuery(20, this.props.l1Reg, this.props.orthReg, this.props.randomIndex, this.props.dataset, this.props.language)
        var language = languageData[this.props.language]

        var classNames = getClassNames(this.props.language, this.props.classNamesMap)

        return (
            <React.Fragment>
                <UiExplanation guideModeOn={this.props.guideModeOn} currentChildren={this.state.explanationPage}>
                    {language["guideMode.explanations"].map(text => <p key={text}>{text}</p>)}
                </UiExplanation>
                <div className="row">
                    <Tile title={language["tile.dt.title"]} tileId="svg-tile">
                        <Loading loading={this.state.loading} />
                        <DecisionTree
                            width={this.props.widthSvg}
                            height={this.props.heightSvg}
                            nodes={this.state.nodes}
                            links={this.state.links}
                            treeSizes={this.state.treeSizes}
                            handleClick={this.handleNodeClick}
                            currentHighlight={[this.state.clickedOn]}
                            featureNamesMap={this.props.featureNamesMap}
                            language={this.props.language}
                            classNamesMap={this.props.classNamesMap} />
                        <div className="parameterInfo">
                            <span className="infoIcon">
                                <i className="material-icons-outlined md-18 md-light">info</i>
                            </span>
                            <p>{language["l1Text"] + " " + this.props.l1Reg}, {language["orText"] + " " + this.props.orthReg}</p>
                        </div>
                    </Tile>

                    <Tile title={language["tile.exp.title"]}>
                        <br />
                        <Accordion title={language["tile.exp.global"]} onChange={this.openGlobalExp}>
                            <Card id_key="rules-prop" label={language["tile.exp.global.rules"]}>
                                <Table
                                    currentHeader={language["tile.exp.global.table"]}
                                    currentRows={this.getDecisionRuleRows(this.state.fetched)}>
                                </Table>
                            </Card>

                            <Card id_key="importance-prop" label={language["tile.exp.global.imps"]}>
                                <div className="chartContainer">
                                    <BarGraph data={topData} labels={topLabels} title={language["tile.exp.global.imps"]} colors={this.props.colors.featureImportances} />
                                </div>
                            </Card>

                            <Card id_key="boundaries-prop" label={language["tile.exp.global.surface"]}>
                                <img
                                    src={this.props.baseUrl + "l1oreg/explanation/global/decision_surface" + query}
                                    alt={language["tile.exp.global.surface"]} />
                            </Card>

                        </Accordion>

                        <Accordion title={language["tile.exp.local"]} onChange={this.openLocalExp}>
                            <Card id_key="node-class-prop" label={language["tile.exp.local.classDis"]}>
                                <div className={`chartContainer ${(this.state.clickedOn === null || this.state.dataDoughnutClass === null) ? "hidden" : ""}`} >
                                    <DoughnutGraph
                                        title={language["tile.exp.local.classDisNode"] + " " + this.state.clickedOn}
                                        data={this.state.dataDoughnutClass}
                                        colors={this.props.colors.classfills}
                                        labels={classNames} />
                                    <p>{language["tile.exp.local.classDisText"][0] + " " + this.getPercentage(this.state.dataDoughnutClass)
                                        + "%" + language["tile.exp.local.classDisText"][1] + "'"
                                        + getFeatureName(this.props.language, this.props.classNamesMap, "Bump") + "'."}</p>
                                </div>
                                <div className={`chartContainer ${(this.state.clickedOn != null || this.state.dataDoughnutClass != null) ? "hidden" : ""}`}>
                                    <p>{language["tile.exp.local.selectNode"]}</p>
                                </div>
                            </Card>
                            <Card id_key="node-count-prop" label={language["tile.exp.local.proportion"]}>
                                <div className={`chartContainer ${(this.state.clickedOn === null || this.state.dataDoughnutCount === null) ? "hidden" : ""}`} >
                                    <DoughnutGraph
                                        title={language["tile.exp.local.proportionNode"] + " " + + this.state.clickedOn}
                                        data={this.state.dataDoughnutCount}
                                        labels={language["tile.exp.local.proportionLabels"]}
                                        colors={this.props.colors.accuracyfills} />
                                    <p>{language["tile.exp.local.proportionText"][0] + " " + this.getPercentage(this.state.dataDoughnutCount) + language["tile.exp.local.proportionText"][1]}</p>
                                </div>
                                <div className={`chartContainer ${(this.state.clickedOn != null || this.state.dataDoughnutCount != null) ? "hidden" : ""}`}>
                                    <p>{language["tile.exp.local.selectNode"]}</p>
                                </div>

                            </Card>
                        </Accordion>
                    </Tile>
                </div>
            </React.Fragment>
        )
    }
}

export default Explanation;
