import React from 'react';

import { Link } from "react-router-dom";

import './Start.scss';

import languageData from '../lang/langStart.json';
import Modal from '../common/Modal';
import Tooltip from '../common/Tooltip';

class Start extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            menuOpen: false,
            showModal: false
        }
    }

    menuClick() {
        this.setState({
            menuOpen: true,
        })
    }

    menuClose() {
        this.setState({
            menuOpen: false,
        })
    }

    openModal = e => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    handleLanguageChange(e) {
        this.props.setLanguage(e.target.value)
    }

    handleGuideChange(e) {
        console.log(e.target.checked)
        this.props.setGuideMode(e.target.checked)
    }

    handleRandomIndexChange(e) {
        this.props.setRandomIndex(e.target.value)
    }

    handleDatasetChange(e) {
        var intern_name = languageData[this.props.language]["choice.dataSetMap"][e.target.value]
        this.props.setDataSet(intern_name)
        console.log(e.target.value, intern_name)
    }

    render() {
        var language = languageData[this.props.language]

        return (
            <React.Fragment>
                <div className="row full-height center">
                    <Link className='text-link start' to="/trainingnn">
                        <button id="button-start">Start</button>
                    </Link>
                    <button className="icon settings upper-right" onClick={e => { this.openModal(e); }}>
                        <i className="material-icons-outlined md-36 md-light">settings</i>
                    </button>
                    <Modal onClose={this.openModal} show={this.state.showModal} title={language["choice.menu"]} >
                        <div className="table">
                            <div className="tableRow">
                                <span className="bold">{language["choice.language"]}</span>
                                <div className="select-wrapper">
                                    <select id="languageChoice" defaultValue={this.props.language === "de" ? "Deutsch" : "English"}
                                        onChange={e => this.handleLanguageChange(e)}>
                                        <option>Deutsch</option>
                                        <option>English</option>
                                    </select>
                                </div>
                            </div>
                            <div className="tableRow">
                                <div>
                                    <span className="bold">{language["choice.guideMode"]}</span>
                                    <Tooltip text={language["choice.giudeModeDesc"]} />
                                </div>
                                <label className="switch">
                                    <input id="guideCheckbox" type="checkbox" defaultChecked={this.props.guideModeOn} onChange={e => this.handleGuideChange(e)}></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="tableRow">
                                <div>
                                    <span className="bold">{language["choice.randomIndex"]}</span>
                                    <Tooltip text={language["choice.randomIndexDesc"]} />
                                </div>
                                <div className="select-wrapper">
                                    <select id="indexChoice" defaultValue={this.props.randomIndex}
                                        onChange={e => this.handleRandomIndexChange(e)}>
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                </div>
                            </div>
                            <div className="tableRow">
                                <span className="bold">{language["choice.dataset"]}</span>
                                <div className="select-wrapper">
                                    <select id="datasetChoice" defaultValue={language["choice.dataSetMap"][this.props.dataset]}
                                        onChange={e => this.handleDatasetChange(e)}>
                                        <option>{language["choice.dataSetMap"]["steel_faults_small"]}</option>
                                        <option>{language["choice.dataSetMap"]["steel_faults_large"]}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </React.Fragment >

        )
    }
}

export default Start;
