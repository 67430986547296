import React from 'react';
import './Graphs.scss';

import { Doughnut } from 'react-chartjs-2';


class DoughnutGraph extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();

        var [data, options] = this.getData()
        this.state = {
            data: data,
            options: options,
        }
    }

    getData() {
        var colors;
        if (!this.props.colors) {
            colors = ["rgba(133,213,218, 0.8)", "rgba(217,84,81, 0.8)"]
        } else {
            colors = this.props.colors
        }
        //var colors = ["rgba(133,213,218, 0.8)", "rgba(217,84,81, 0.8)"] // generateColors(this.props.data.length)
        const dataP = this.props.data
        var data = {
            datasets: [{
                data: dataP,
                backgroundColor: colors,
            }],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: this.props.labels,
        }

        var options = {
            // legend: { display: false },
            title: {
                display: true,
                text: this.props.title,
                fontColor: "#EFEEEE",
            },
            legend: {
                display: true,
                labels: {
                    fontColor: "#EFEEEE",
                },
            },
        }

        return [data, options]
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            var [data, options] = this.getData()
            this.setState({
                data: data,
                options: options,
            })
        }
    }

    render() {
        // data has to be part of state for reset to work correctly
        return (
            <div className="line-chart">
                <Doughnut ref={this.chartRef} data={this.state["data"]} options={this.state["options"]} />
            </div>
        )
    }
}

export default DoughnutGraph;