
export const configApp = {
    baseUrl: "/",
    widthSvg: 700,
    heightSvg: 450,
}

export const configData = {
    steel_faults_large: {
        structureNN: [27, 20, 10, 1],
        featureNames: {
            "en": ["X_Minimum", "X_Maximum", "Y_Minimum", "Y_Maximum", "Pixels_Areas", "X_Perimeter", "Y_Perimeter", "Sum_of_Luminosity", "Minimum_of_Luminosity", "Maximum_of_Luminosity", "Length_of_Conveyer", "TypeOfSteel_A300", "TypeOfSteel_A400", "Steel_Plate_Thickness", "Edges_Index", "Empty_Index", "Square_Index", "Outside_X_Index", "Edges_X_Index", "Edges_Y_Index", "Outside_Global_Index", "LogOfAreas", "Log_X_Index", "Log_Y_Index", "Orientation_Index", "Luminosity_Index", "SigmoidOfAreas"],
            "de": ["X_Minimum", "X_Maximum", "Y_Minimum", "Y_Maximum", "Pixel_Flaeche", "X_Umfang", "Y_Perimeter", "Summe_Helligkeit", "Minimum_Helligkeit", "Maximum_Helligkeit", "Laenge_Band", "Stahlart_A300", "Stahlart_A400", "Stahlplattendicke", "Kanten_Index", "Leere_Index", "Quadrat_Index", "Außen_X_Index", "Kanten_X_Index", "Kanten_Y_Index", "Außen_Global_Index", "Logarithmus_Flaeche", "Logarithmus_X_Index", "Logarithmus_Y_Index", "Orientierungs_Index", "Helligkeits_Index", "Sigmoid_Flaeche"],
        },
        outputNames: {
            "en": ['Error_Type'],
            "de": ['Fehlertyp'],
        },
        featureNamesMap: { "X_Minimum": "X_Minimum", "X_Maximum": "X_Maximum", "Y_Minimum": "Y_Minimum", "Y_Maximum": "Y_Maximum", "Pixels_Areas": "Pixel_Flaeche", "X_Perimeter": "X_Umfang", "Y_Perimeter": "Y_Perimeter", "Sum_of_Luminosity": "Summe_Helligkeit", "Minimum_of_Luminosity": "Minimum_Helligkeit", "Maximum_of_Luminosity": "Maximum_Helligkeit", "Length_of_Conveyer": "Laenge_Band", "TypeOfSteel_A300": "Stahlart_A300", "TypeOfSteel_A400": "Stahlart_A400", "Steel_Plate_Thickness": "Stahlplattendicke", "Edges_Index": "Kanten_Index", "Empty_Index": "Leerer_Index", "Square_Index": "Quadrat_Index", "Outside_X_Index": "Außen_X_Index", "Edges_X_Index": "Kanten_X_Index", "Edges_Y_Index": "Kanten_Y_Index", "Outside_Global_Index": "Außen_Global_Index", "LogOfAreas": "Logarithmus_Flaeche", "Log_X_Index": "Logarithmus_X_Index", "Log_Y_Index": "Logarithmus_Y_Index", "Orientation_Index": "Orientierungs_Index", "Luminosity_Index": "Helligkeits_Index", "SigmoidOfAreas": "Sigmoid_Flaeche" },
        classNamesMap: { "Bump": "Beule", "Scratch": "Kratzer" },
        nEpochs: 20,
    },
    steel_faults_small: {
        structureNN: [15, 20, 10, 1],
        featureNames: {
            "en": ['Y_Minimum', 'Y_Maximum', 'Pixels_Areas', 'SigmoidOfAreas', 'Minimum_of_Luminosity', 'Edges_Index', 'Outside_X_Index', 'Steel_Plate_Thickness', 'Log_Y_Index', 'Sum_of_Luminosity', 'Length_of_Conveyer', 'X_Maximum', 'Log_X_Index', 'X_Minimum', 'LogOfAreas'],
            "de": ['Y_Minimum', 'Y_Maximum', 'Pixel_Flaeche', 'Sigmoid_Flaeche', 'Minimum_Helligkeit', 'Kanten_Index', 'Außen_X_Index', 'Stahlplattendicke', 'Logarithmus_Y_Index', 'Summe_Helligkeit', 'Laenge_Band', 'X_Maximum', 'Logarithmus_X_Index', 'X_Minimum', 'Logarithmus_Flaeche'],
        },
        outputNames: {
            "en": ['Error_Type'],
            "de": ['Fehlertyp'],
        },
        featureNamesMap: { "X_Minimum": "X_Minimum", "X_Maximum": "X_Maximum", "Y_Minimum": "Y_Minimum", "Y_Maximum": "Y_Maximum", "Pixels_Areas": "Pixel_Flaeche", "X_Perimeter": "X_Umfang", "Y_Perimeter": "Y_Perimeter", "Sum_of_Luminosity": "Summe_Helligkeit", "Minimum_of_Luminosity": "Minimum_Helligkeit", "Maximum_of_Luminosity": "Maximum_Helligkeit", "Length_of_Conveyer": "Laenge_Band", "TypeOfSteel_A300": "Stahlart_A300", "TypeOfSteel_A400": "Stahlart_A400", "Steel_Plate_Thickness": "Stahlplattendicke", "Edges_Index": "Kanten_Index", "Empty_Index": "Leerer_Index", "Square_Index": "Quadrat_Index", "Outside_X_Index": "Außen_X_Index", "Edges_X_Index": "Kanten_X_Index", "Edges_Y_Index": "Kanten_Y_Index", "Outside_Global_Index": "Außen_Global_Index", "LogOfAreas": "Logarithmus_Flaeche", "Log_X_Index": "Logarithmus_X_Index", "Log_Y_Index": "Logarithmus_Y_Index", "Orientation_Index": "Orientierungs_Index", "Luminosity_Index": "Helligkeits_Index", "SigmoidOfAreas": "Sigmoid_Flaeche" },
        classNamesMap: { "Bump": "Beule", "Scratch": "Kratzer" },
        nEpochs: 20,
    }
}

export const configColors = {
    classfills: ["#009474", "#FF7D2C", "#052A5E", "#E69000"],
    accuracyfills: ["rgba(255,255,255, 0.8)", "#FFC78C"],
    featureImportances: ["#FF6F5F", "#FF9D75", "#FFC78C", "#FFE39F", "#A7D2D6"]
}/* Color Theme Swatches in Hex */

