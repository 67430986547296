import React from 'react';

import './Tooltip.scss';

class Tooltip extends React.Component {
    render() {
        return (
            <div className="tooltip icon">
                <i className="material-icons-outlined md-18 md-light">help_outline</i>
                <span className="tooltiptext">{this.props.text}</span>
            </div>
        )
    }
}

export default Tooltip;

