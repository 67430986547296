import React from 'react';
import logo from '../img/ipa_logo.png';
import xailogo from '../img/xAI-logo_text.svg';
import './Toolbar.scss';

import { Link } from "react-router-dom";

class Toolbar extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="toolbar">
                    <div className="title">
                        <img src={xailogo} className="logo-xai" alt="Logo xAI" />
                        <span>Demonstrator</span>
                    </div>
                    <Link className='arrow-link' to="">
                        <img src={logo} className="logo" alt="Logo IPA" />
                    </Link>

                </div>
            </div>
        )
    }
}

export default Toolbar;
