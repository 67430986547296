import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './App.scss';

//pages
import Start from './pages/Start';
import TrainingNN from './pages/TrainingNN';
import InspectionNN from './pages/InspectionNN';
import TrainingDT from './pages/TrainingDT';
import InspectionDT from './pages/InspectionDT';
import Explanation from './pages/Explanation';
import Contacts from './pages/Contacts';
import Footer from './common/Footer';

// common
import Toolbar from './common/Toolbar';
import WithNav from './common/WithNav';

//config
import { configApp, configData, configColors } from './config/config';

// Settings

const TrainingNNWithNav = WithNav(TrainingNN) //WithNav is a HOC that inserts a navigation element below
const InspectionNNWithNav = WithNav(InspectionNN)
const TrainingDTWithNav = WithNav(TrainingDT)
const InspectionDTWithNav = WithNav(InspectionDT)
const ExplanationWithNav = WithNav(Explanation)
const ContactsWithNav = WithNav(Contacts)


var basicProps = {
    heightSvg: configApp.heightSvg,
    baseUrl: configApp.baseUrl,
    colors: configColors
}

class App extends React.Component {

    constructor(props) {
        super(props)

        var default_dataset = "steel_faults_small"
        var default_language = "de"
        this.state = {
            l1Reg: 0.0,
            orthReg: 0.0,
            language: default_language,
            guideModeOn: true,
            randomIndex: 0,
            dataset: default_dataset,
            nEpochs: configData[default_dataset].nEpochs,
            featureNamesMap: configData[default_dataset].featureNamesMap,
            classNamesMap: configData[default_dataset].classNamesMap,
            structureNN: configData[default_dataset].structureNN,
            featureNames: configData[default_dataset].featureNames[default_language],
            outputNames: configData[default_dataset].outputNames[default_language],
            widthSvg: configApp.widthSvg,
            isMobile: false
        }
        this.updateSvgWidth = this.updateSvgWidth.bind(this);
    }

    updateWindowDimensions() {
        if (window.innerWidth < 600) {
            this.setState({ isMobile: true })
        } else {
            this.setState({ isMobile: false })
        }
    }

    setRegularization(id, value) {
        var state = {}
        state[id] = value
        this.setState(state)
    }

    setLanguage(value) {
        value = (value === "Deutsch") ? "de" : "en"
        this.setState({
            language: value,
            featureNames: configData[this.state.dataset].featureNames[value],
            outputNames: configData[this.state.dataset].outputNames[value]
        })
    }

    setGuideMode(value) {
        this.setState({
            guideModeOn: value,
        })

    }

    setRandomIndex(value) {
        this.setState({
            randomIndex: value,
        })
    }

    setDataSet(value) {
        this.setState({
            dataset: value,
            nEpochs: configData[value].nEpochs,
            featureNamesMap: configData[value].featureNamesMap,
            classNamesMap: configData[value].classNamesMap,
            structureNN: configData[value].structureNN,
            featureNames: configData[value].featureNames[this.state.language],
            outputNames: configData[value].outputNames[this.state.language],
        })
    }

    updateSvgWidth() {
        console.log("Update scf")
        if (document.getElementById("svg-tile") !== null) {
            this.setState({
                widthSvg: document.getElementById("svg-tile").clientWidth
            })
        }

    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }

    render() {
        return (
            <Router>
                <div className="content">
                    <Toolbar />
                    <Switch>
                        <Route path="/" exact>
                            <Start
                                {...basicProps}
                                {...this.state}
                                setLanguage={value => this.setLanguage(value)}
                                setGuideMode={value => this.setGuideMode(value)}
                                setRandomIndex={value => this.setRandomIndex(value)}
                                setDataSet={value => this.setDataSet(value)}
                                nextLocation="trainingnn"
                            />
                        </Route>
                        <Route path="/trainingnn">
                            <TrainingNNWithNav
                                {...basicProps}
                                {...this.state}
                                updateSvgWidth={this.updateSvgWidth}
                                previousLocation=""
                                currentLocation="trainingnn"
                                nextLocation="inspectionnn"
                                setRegularization={(id, value) => this.setRegularization(id, value)}
                            />
                        </Route>
                        <Route path="/inspectionnn">
                            <InspectionNNWithNav
                                {...basicProps}
                                {...this.state}
                                updateSvgWidth={this.updateSvgWidth}
                                previousLocation="trainingnn"
                                currentLocation="inspectionnn"
                                nextLocation="trainingdt"
                            />
                        </Route>
                        <Route path="/trainingdt">
                            <TrainingDTWithNav
                                {...basicProps}
                                {...this.state}
                                updateSvgWidth={this.updateSvgWidth}
                                previousLocation="inspectionnn"
                                currentLocation="trainingdt"
                                nextLocation="inspectiondt"
                            />
                        </Route>
                        <Route path="/inspectiondt">
                            <InspectionDTWithNav
                                {...basicProps}
                                {...this.state}
                                updateSvgWidth={this.updateSvgWidth}
                                previousLocation="trainingdt"
                                currentLocation="inspectiondt"
                                nextLocation="explanation"
                            />
                        </Route>
                        <Route path="/explanation">
                            <ExplanationWithNav
                                {...basicProps}
                                {...this.state}
                                updateSvgWidth={this.updateSvgWidth}
                                previousLocation="inspectiondt"
                                currentLocation="explanation"
                                nextLocation="contacts"
                            />
                        </Route>
                        <Route path="/contacts">
                            <ContactsWithNav
                                {...basicProps}
                                {...this.state}
                                updateSvgWidth={this.updateSvgWidth}
                                previousLocation="explanation"
                                currentLocation="contacts"
                                nextLocation=""
                            />
                        </Route>
                    </Switch>
                </div>
                <Footer
                    language={this.state.language}
                />
            </Router>
        );
    }
}


export default App;
