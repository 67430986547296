import React from 'react';

import Tile from '../common/Tile';
import Table from '../common/Table'
import DecisionTree from '../models/DecisionTree';
import UiExplanation from '../common/UiExplanation';
import Loading from '../common/Loading';

import { buildQuery, getFeatureName } from '../common/helpers';

import languageData from '../lang/langDTInspection.json';


class InspectionDT extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            nodes: null,
            links: null,
            treeSizes: null,
            scores: null,
            currentDecisionIndex: 0,
            loading: true,
            depth: 1,
            decisionNodesSample: [],
        }
    }

    nextClicked() {
        var currentDecisionIndex = this.state.currentDecisionIndex
        if (currentDecisionIndex < this.state.decisionNodesSample.length - 1) {
            currentDecisionIndex += 1
            this.setState({
                currentDecisionIndex: currentDecisionIndex
            })
        }
    }

    prevClicked() {
        var currentDecisionIndex = this.state.currentDecisionIndex
        if (this.state.currentDecisionIndex > 0) {
            currentDecisionIndex -= 1
            this.setState({
                currentDecisionIndex: currentDecisionIndex
            })
        }
    }

    componentDidMount() {
        this.props.updateSvgWidth();
        var date = new Date()
        this.startTime = date.getTime()
        var query = buildQuery(20, this.props.l1Reg, this.props.orthReg, this.props.randomIndex, this.props.dataset)
        fetch(this.props.baseUrl + "l1oreg/explanation" + query)
            .then(res => res.json())
            .then(res => this.insertTreeData(res))
            .catch((error) => {
                console.error('Error:', error);
            })
        fetch(this.props.baseUrl + "l1oreg/explanation/introspection" + query)
            .then(res => res.json())
            .then(res => this.insertInspectionData(res))
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    getHightlight(currentDecisionIndex, string = false) {
        if (string) {
            if (this.state.fetched) {
                if (currentDecisionIndex < this.state.decisionNodesSample.length - 1) {
                    return getFeatureName(this.props.language, this.props.featureNamesMap, this.state.decisionNodesSample[this.state.currentDecisionIndex].feature_name)
                }
            }

            return null
        }
        else {
            if (this.state.fetched) {
                return [this.state.decisionNodesSample[this.state.currentDecisionIndex].node_id]
            }
            return []
        }
    }

    getRows(fetched) {
        if (fetched) {
            var currentRows = []
            for (var i = 0; i < this.state.sample.length; ++i) {
                currentRows.push([this.props.featureNames[i], this.state.sample[i].toFixed(1)])
            }
            return currentRows
        }
        else {
            return []
        }
    }

    insertInspectionData(res) {
        this.setState({
            decisionNodesSample: res.decision_nodes_sample,
            sample: res.sample,
            sampleTrueClass: res.sample_true_class,
            fetched: true,
        })
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching inspectionData took", endTime - this.startTime, "ms")
    }

    insertTreeData(res) {
        this.setState({
            nodes: res.nodes,
            links: res.links,
            treeSizes: res.tree_sizes,
            scores: res.scores,
            loading: false,
            depth: Object.keys(res.tree_sizes).length
        })
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching treeStructure took", endTime - this.startTime, "ms")
    }

    render() {
        var language = languageData[this.props.language]

        return (
            <React.Fragment>
                <UiExplanation guideModeOn={this.props.guideModeOn} currentChildren={0}>
                    {language["guideMode.explanations"].map(text => <p key={text}>{text}</p>)}
                </UiExplanation>
                <div className="row">
                    <Tile title={language["tile.insp.title"]} tileId="svg-tile">
                        <Loading loading={this.state.loading} />
                        <div className="actionBar">
                            <div className="layerNavigation">
                                <button className="icon" disabled={this.state.loading || this.state.currentDecisionIndex === 0} onClick={() => this.prevClicked()} title={language["tile.insp.prev"]}>
                                    <i className={`material-icons-outlined md-36 md-light ${this.state.loading || this.state.currentDecisionIndex === 0 ? "md-inactive" : ""}`}>navigate_before</i>
                                </button>
                                <span className="bold">{language["tile.insp.layer"] + " " + (this.state.currentDecisionIndex + 1)}</span>
                                <button className="icon" disabled={this.state.loading || this.state.currentDecisionIndex === this.state.decisionNodesSample.length - 1} onClick={() => this.nextClicked()} title={language["tile.insp.next"]}>
                                    <i className={`material-icons-outlined md-36 md-light ${this.state.loading || this.state.currentDecisionIndex === this.state.decisionNodesSample.length - 1 ? "md-inactive" : ""}`}>navigate_next</i>
                                </button>
                            </div>
                        </div>
                        <DecisionTree
                            width={this.props.widthSvg}
                            // height={this.props.heightSvg}
                            height={(this.state.depth * 120)}
                            nodes={this.state.nodes}
                            links={this.state.links}
                            treeSizes={this.state.treeSizes}
                            currentHighlight={this.getHightlight(this.state.currentDecisionIndex, false)}
                            featureNamesMap={this.props.featureNamesMap}
                            language={this.props.language}
                            classNamesMap={this.props.classNamesMap} />
                        {/* <div className="parameterInfo">
                            <span className="infoIcon">
                                <i className="material-icons-outlined md-18 md-light">info</i>
                            </span>
                            <p>{language["l1Text"] + " " + this.props.l1Reg}, {language["orText"] + " " + this.props.orthReg}</p>
                        </div> */}
                    </Tile>
                    <Tile title={language["title.pred.title"]}>
                        <Table
                            currentHeader={language["tile.pred.table"]}
                            currentRows={this.getRows(this.state.fetched)}
                            hightlightFeatureRow={this.getHightlight(this.state.currentDecisionIndex, true)}>
                            <p>{language["tile.stat.class"] + " '" +
                                getFeatureName(this.props.language, this.props.classNamesMap, this.state.sampleTrueClass) + "'"}.</p>
                        </Table>
                    </Tile>
                </div>
            </React.Fragment>
        )
    }
}

export default InspectionDT;
