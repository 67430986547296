import React from 'react';
import './Pages.scss';

import Tile from '../common/Tile';
// import Table from '../common/Table'
import DecisionTree from '../models/DecisionTree';
import UiExplanation from '../common/UiExplanation';
import Loading from '../common/Loading';
import DoughnutGraph from '../charts/DoughnutGraph';

import { buildQuery } from '../common/helpers';

import surr_extraction_dt from '../img/surrogat_extraction_dt.svg';
import surr_extraction_engl from '../img/surrogat_extraction_engl.svg';


import languageData from '../lang/langDTTraining.json';

class TrainingDT extends React.Component {

    constructor(props) {

        super(props)

        this.state = {
            styleTrainingClicked: {
                nodes: null,
                links: null,
                treeSizes: null,
                scores: null,
                hidden: true,
            },
            fullyTrained: false,
            depth: 1
        }
        console.log(this.props.widthSvg)
    }

    trainClicked() {
        this.setState({
            styleTrainingClicked: {
                hidden: false
            },
            fullyTrained: true
        })
    }

    resetClicked() {
        this.setState({
            styleTrainingClicked: {
                hidden: true
            },
            fullyTrained: false
        })
    }

    getRows(language) {
        if (this.state.scores != null) {
            return [
                [language["tile.stat.accuracy"], this.state.scores["acc_test"].toFixed(2)],
                [language["tile.stat.fidelity"], this.state.scores["fidelity_test"].toFixed(2)],
            ]
        }
        else {
            return []
        }

    }

    getScores(language, metric) {
        var return_val = [];
        if (this.state.scores != null) {
            if (metric === "acc_test") {
                return_val = [this.state.scores["acc_test"].toFixed(2), (1 - this.state.scores["acc_test"]).toFixed(2)]
            } else if (metric === "fidelity_test") {
                return_val = [this.state.scores["fidelity_test"].toFixed(2), (1 - this.state.scores["fidelity_test"]).toFixed(2)]
            }
            return return_val;
        }
        else {
            return return_val;
        }
    }

    componentDidMount() {
        this.props.updateSvgWidth();
        this.loadTreeStructure()
    }

    loadTreeStructure() {
        var date = new Date()
        this.startTime = date.getTime()
        var query = buildQuery(20, this.props.l1Reg, this.props.orthReg, this.props.randomIndex, this.props.dataset)
        fetch(this.props.baseUrl + "l1oreg/explanation" + query)
            .then(res => res.json())
            .then(res => this.insertTreeData(res))
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    insertTreeData(res) {
        this.setState({
            nodes: res.nodes,
            links: res.links,
            treeSizes: res.tree_sizes,
            scores: res.scores,
            depth: Object.keys(res.tree_sizes).length
        })
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching treeStructure took", endTime - this.startTime, "ms")
    }

    render() {
        var language = languageData[this.props.language]

        return (
            <React.Fragment>
                <UiExplanation guideModeOn={this.props.guideModeOn} currentChildren={this.state.fullyTrained ? 1 : 0}>
                    {language["guideMode.explanations"].map(text => <p key={text}>{text}</p>)}
                </UiExplanation>
                <div className="row">
                    <Tile title={language["tile.dt.title"]} tileId="svg-tile">
                        <Loading loading={this.state.nodes == null} />
                        <div className="actionBar">
                            <button className="icon" title={language['tile.dt.train']} disabled={this.state.nodes == null || this.state.fullyTrained} onClick={() => this.trainClicked()}>
                                <i className={`material-icons-outlined md-36 md-light ${this.state.fullyTrained ? "md-inactive" : ""}`}>play_arrow</i>
                            </button>
                            <button className="icon" title={language['tile.dt.reset']} disabled={this.state.nodes == null} onClick={() => this.resetClicked()}>
                                <i className="material-icons-outlined md-36 md-light">settings_backup_restore</i>
                            </button>
                        </div>
                        {!this.state.fullyTrained
                            &&
                            <React.Fragment>
                                {
                                    this.props.language === "de" ? (
                                        <img src={surr_extraction_dt} className="extraction-process" alt={language["infoimg.alttext"]} />
                                    ) : (
                                            <img src={surr_extraction_engl} className="extraction-process" alt={language["infoimg.alttext"]} />
                                        )
                                }
                            </React.Fragment>
                        }

                        <DecisionTree
                            className={`${this.state.styleTrainingClicked.hidden ? "hidden" : ""}`}
                            width={this.props.widthSvg}
                            height={(this.state.depth * 120)}
                            nodes={this.state.nodes}
                            links={this.state.links}
                            treeSizes={this.state.treeSizes}
                            featureNamesMap={this.props.featureNamesMap}
                            language={this.props.language}
                            classNamesMap={this.props.classNamesMap} />
                        {/* <div className="parameterInfo">
                            <span className="infoIcon">
                                <i className="material-icons-outlined md-18 md-light">info</i>
                            </span>
                            <p>{language["l1Text"] + " " + this.props.l1Reg}, {language["orText"] + " " + this.props.orthReg}</p>
                        </div> */}
                    </Tile>
                    <Tile title={language["title.stat.title"]}>
                        {/* <Table
                            className={`${this.state.styleTrainingClicked.hidden ? "hidden" : ""}`}
                            currentHeader={language["tile.stat.table"]}
                            currentRows={this.getRows(language)}>
                            <br />
                        </Table> */}
                        <div className={`chartContainer ${this.state.styleTrainingClicked.hidden ? "hidden" : ""}`}>
                            <DoughnutGraph
                                title={language["tile.stat.accuracy"]}
                                data={this.getScores(language, "acc_test")}
                                colors={this.props.colors.accuracyfills}
                                labels={[language["tile.stat.desc.correct"], language["tile.stat.desc.false"]]}>
                            </DoughnutGraph>
                            <DoughnutGraph
                                title={language["tile.stat.fidelity"]}
                                data={this.getScores(language, "fidelity_test")}
                                colors={this.props.colors.accuracyfills}
                                labels={[language["tile.stat.desc.correct"], language["tile.stat.desc.false"]]}>
                            </DoughnutGraph>
                        </div>

                    </Tile>
                </div>
            </React.Fragment>
        )
    }
}

export default TrainingDT;
