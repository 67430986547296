import React from 'react';

import './Accordion.scss'

class Accordion extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            "open": false,
        }
    }

    toggleOpen() {
        this.setState({
            "open": !this.state.open,
        })
    }

    render() {
        return (
            <React.Fragment>
                <button
                    onClick={() => {this.toggleOpen(); this.props.onChange(this.state.open);}}
                    className={"accordion " + (this.state.open ? "active" : "")}>

                    {this.props.title}
                    <i className="material-icons-outlined md-36 down">keyboard_arrow_down</i>
                    <i className="material-icons-outlined md-36 up">keyboard_arrow_up</i>
                </button>
                <div className="panel">
                    {this.props.children}
                </div>

            </React.Fragment>

        )
    }
}


export default Accordion;