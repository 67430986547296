import React from 'react';

import './TrainingNN.scss';

import Tile from '../common/Tile';
import LineGraph from '../charts/LineGraph'
import NeuralNetwork from '../models/NeuralNet';
import UiExplanation from '../common/UiExplanation';
import Loading from '../common/Loading';

import { buildQuery } from '../common/helpers';

import languageData from '../lang/langNNTraining.json';

class TrainingNN extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            epoch: 0,
            loss: [],
            accuracy: [],
            fetchedHistory: false,
            fetchedWeights: false,
            mlpLinks: null,
            loading: true,
            fullyTrained: false,
        }

        this.handleSelectChange = this.handleSelectChange.bind(this) // needed to be able to acces this.handleSelectChange in some cases

        this.allMlpLinks = {}
    }

    componentDidMount() {
        this.props.updateSvgWidth();
        this.loadAllData()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.l1Reg !== this.props.l1Reg) || (prevProps.orthReg !== this.props.orthReg)) {
            this.allMlpLinks = {}
            this.loadAllData()
            this.setState({
                loading: true,
            })
            this.resetClicked()
        }
    }

    changeData() {
        var current_epoch = this.state["epoch"]
        var loss = []
        var accuracy = []

        if (current_epoch < this.props.nEpochs - 1) {
            current_epoch = current_epoch + 1
        }

        for (var i = 0; i < current_epoch; ++i) {
            if (this.state.fetchedHistory) {
                loss.push(this.state.lossTest[i])
                accuracy.push(this.state.accuracyTest[i])
            }
        }

        if (current_epoch === this.props.nEpochs - 1) {
            this.setState({
                fullyTrained: true,
                explanationPage: 1,
            })
        }

        this.setState({
            epoch: current_epoch,
            loss: loss,
            accuracy: accuracy,
            mlpLinks: this.allMlpLinks[current_epoch],
        })
    }

    trainingClicked() {
        console.log("trainingClicked")
        const delay = 75
        if (this.state.fetchedWeights && this.state.fetchedHistory) {
            for (var i = 0; i < this.props.nEpochs; i++) {
                setTimeout(() => this.changeData(), i * delay)
            }
        }
        else {
            console.log("Have not fetched weights and history yet.")
        }
    }

    resetClicked() {
        console.log("resetClicked")
        if (this.state.fetchedHistory && this.state.fetchedWeights) {
            this.setState({
                epoch: 0,
                loss: [],
                accuracy: [],
                mlpLinks: this.allMlpLinks["1"],
                fullyTrained: false,
            })
        }
        else {
            console.log("Warning. Have not fetched weights and history yet.")
            this.setState({
                epoch: 0,
                loss: [],
                accuracy: [],
                mlpLinks: null,
                fullyTrained: false,
                explanationPage: 0,
            })
        }
    }

    handleSelectChange(e) {
        this.props.setRegularization(e.target.id, e.target.value)
        this.setState({ explanationPage: 2 })
    }

    loadAllData() {
        var date = new Date()
        this.startTime = date.getTime()

        this.setState({
            fetchedWeights: false,
            fetchedHistory: false,
        })
        this.loadHistory()
        for (var i = 0; i < this.props.nEpochs + 1; i++) {
            this.loadWeights(i)
        }
    }

    loadHistory() {
        var query = buildQuery(this.state.epoch, this.props.l1Reg, this.props.orthReg, this.props.randomIndex, this.props.dataset)
        fetch(this.props.baseUrl + "l1oreg/model/training" + query)
            .then(res => res.json())
            .then(res => this.insertHistory(res))
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    loadWeights(epoch) {
        var query = buildQuery(epoch, this.props.l1Reg, this.props.orthReg, this.props.randomIndex, this.props.dataset)
        fetch(this.props.baseUrl + "l1oreg/model/training/weights" + query)
            .then(res => res.json())
            .then(res => this.insertWeighs(res, epoch))
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    insertWeighs(res, epoch) {
        this.allMlpLinks[String(epoch)] = res.mlp_links

        if (Object.keys(this.allMlpLinks).length === this.props.nEpochs + 1) { // Loaded all epochs
            console.log("Found all")
            this.setState({
                fetchedWeights: true,
            })

            var date = new Date()
            var endTime = date.getTime()
            console.log("Fetching weights took", endTime - this.startTime, "ms")
        }

        if (epoch === 0) { // Loaded epoch zero (randomly initialized weights). Can show NN now
            console.log("Loaded epoch 0")
            this.setState({
                mlpLinks: res.mlp_links,
                loading: false,
            })
        }


    }

    insertHistory(res) {
        this.setState({
            accuracyTest: res.accuracy_test,
            lossTest: res.loss_test,
            fetchedHistory: true,
        })
        var date = new Date()
        var endTime = date.getTime()
        console.log("Fetching History took", endTime - this.startTime, "ms")
    }

    render() {
        var language = languageData[this.props.language]

        return (
            <React.Fragment>
                <UiExplanation guideModeOn={this.props.guideModeOn} currentChildren={this.state.explanationPage}>
                    {language["guideMode.explanations"].map(text => <p key={text}>{text}</p>)}
                </UiExplanation>
                <div className="row">
                    <Tile title={language["tile.nn.title"]} tileId="svg-tile">
                        <div className="actionBar ">
                            <div className="container c-1-2">
                                <div className="parameterSettings">
                                    <span className="bold desc">{language["regularizationText"]}</span>
                                    <div>
                                        <div className="parameter-options">
                                            <span className="label">{language["l1Text"]}</span>
                                            <div className="select-wrapper">
                                                <select id="l1Reg" disabled={!this.state.fetchedWeights} value={this.props.l1Reg} onChange={this.handleSelectChange}>
                                                    <option>0.0</option>
                                                    <option>0.001</option>
                                                    <option>0.01</option>
                                                    <option>0.1</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="parameter-options">
                                            <span className="label">{language["orText"]}</span>
                                            <div className="select-wrapper">
                                                <select id="orthReg" disabled={!this.state.fetchedWeights} value={this.props.orthReg} onChange={this.handleSelectChange}>
                                                    <option>0.0</option>
                                                    <option>0.001</option>
                                                    <option>0.01</option>
                                                    <option>0.1</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container c-1-2">
                                <div>
                                    <button className="icon" title={language['tile.nn.train']} disabled={!this.state.fetchedWeights || this.state.fullyTrained} onClick={() => this.trainingClicked()}>
                                        <i className={`material-icons-outlined md-36 md-light ${!this.state.fetchedWeights || this.state.fullyTrained ? "md-inactive" : ""}`}>play_arrow</i>
                                    </button>
                                    <button className="icon" title={language['tile.nn.reset']} disabled={!this.state.fullyTrained} onClick={() => this.resetClicked()}>
                                        <i className={`material-icons-outlined md-36 md-light ${!this.state.fullyTrained ? "md-inactive" : ""}`}>settings_backup_restore</i>
                                    </button>
                                </div>

                            </div>

                        </div>
                        <Loading loading={this.state.loading}></Loading>
                        <NeuralNetwork
                            structure={this.props.structureNN}
                            width={this.props.widthSvg}
                            height={this.props.heightSvg}
                            epoch={this.state.epoch}
                            mlpLinks={this.state.mlpLinks}
                            featureNames={this.props.featureNames}
                            outputNames={this.props.outputNames} />


                    </Tile>
                    <Tile title={language["tile.stat.title"]} class="chartContainer">
                        <LineGraph
                            title={language["tile.stat.loss"]}
                            data={this.state["loss"]}
                            color={this.props.colors.accuracyfills[1]}
                            xLabel={language["tile.stat.xLabel"]}
                            labelHover={language["tile.stat.lossVal"]}
                            />
                        <LineGraph
                            title={language["tile.stat.acc"]}
                            data={this.state["accuracy"]}
                            color={this.props.colors.accuracyfills[0]}
                            xLabel={language["tile.stat.xLabel"]}
                            labelHover={language["tile.stat.acc"]}
                            />
                    </Tile>
                </div>
            </React.Fragment >
        )
    }
}

export default TrainingNN;
