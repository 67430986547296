import React from "react";
import "./Modal.scss";
import PropTypes from "prop-types";

export default class Modal extends React.Component {

    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    render() {

        if (!this.props.show) {
            return null;
        }

        return (
            <div className="modal" id="modal">
                <button className="icon close" onClick={this.onClose}>
                    <i className="material-icons-outlined md-36 md-light">close</i>
                </button>
                <h2>{this.props.title}</h2>
                <div className="content">{this.props.children}</div>
            </div>
        );
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired
};