import React from 'react';
import './Graphs.scss';

import { Bar } from 'react-chartjs-2';

function randomRgba(alpha = 1) {
    const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
    const randomByte = () => randomNumber(0, 255)
    const randomCssRgba = () => `rgb(${[randomByte(), randomByte(), randomByte(), alpha].join(',')})`
    return randomCssRgba()
}

function generateColors(num) {
    var palette = ["#DA2864", "#F08080", "#F3E9AB", "#8EE6BB", "#16A2A8"]
    var colors = []

    for (var i = 0; i < num; i++) {
        if (i < palette.length) {
            colors.push(palette[i])
        } else {
            let color = randomRgba()
            colors.push(color)
        }
    }
    return colors;
}

class BarGraph extends React.Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();

        var [data, options] = this.getData()
        this.state = {
            data: data,
            options: options,
        }

    }

    getData() {
        var colors;
        if (!this.props.colors) {
            colors = generateColors(this.props.data.length)
        } else {
            colors = this.props.colors
        }
        // var colors = generateColors(this.props.data.length)
        var data = {
            datasets: [{
                data: this.props.data,
                backgroundColor: colors
            }],
            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: this.props.labels
        }

        let grid_color = "rgba(239, 238, 238, 0.1)"
        var options = {
            legend: { display: false },
            title: {
                display: true,
                text: this.props.title,
                fontColor: "#EFEEEE",
            },
            scales: {
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        color: grid_color,
                        zeroLineColor: grid_color,
                    },
                    ticks: {
                        fontColor: "#EFEEEE",
                    }
                }],
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        color: grid_color,
                        zeroLineColor: grid_color,
                    },
                    ticks: {
                        fontColor: "#EFEEEE",
                    }
                }]
            },
        }
        return [data, options]
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            var [data, options] = this.getData()
            this.setState({
                data: data,
                options: options,
            })
        }
    }

    render() {
        // data has to be part of state for reset to work correctly
        return (
            <div className="line-chart">
                <Bar ref={this.chartRef} data={this.state["data"]} options={this.state["options"]} />
            </div>
        )
    }
}

export default BarGraph;